import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Root = styled.div`
  background-color: ${p => p.theme.blueDark};
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  overflow: hidden;
  border-radius: ${p => p.borderRadius};
  ${p => p.shadow && `
    box-shadow: ${p.shadow};
  `}

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const Video = ({ children, maxWidth, borderRadius, shadow }) => {
  return <Root maxWidth={maxWidth} borderRadius={borderRadius} shadow={shadow}>{children}</Root>;
};

Video.propTypes = {
  children: PropTypes.object,
  maxWidth: PropTypes.string,
  borderRadius: PropTypes.string,
  shadow: PropTypes.string,
};

export default Video;
