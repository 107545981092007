import React from "react";
import { withTheme } from "styled-components";
import PropTypes from "prop-types";
import HTMLRendererPackage from 'react-html-renderer';

import Heading from "../components/typography/heading";
import Text from "../components/typography/text";
import Box from "../components/containers/box";
import Link from "../components/links/link";
import UnorderedList from "../components/lists/unorderedList";
import ListItem from "../components/lists/listItem";
import Image from "../components/globals/image";
import Video from "../components/video/video";

// This file is use to render Prismic RichText fields using the projects components
const HTMLRenderer = ({ html, theme, ...rest }) => {
  return (
    <HTMLRendererPackage
      html={html}
      components={{
        h2: props => <Heading as="h2" variant="h2" {...props} />,
        h3: props => <Heading as="h3" variant="h3" {...props} />,
        h4: props => <Heading as="h4" variant="h4" {...props} />,
        h5: props => <Heading as="h5" variant="h5" {...props} />,
        p: Text,
        strong: props => <Text as="strong" fontFamily={theme.headingFont} color={theme.greyDark} {...props} />,
        a: ({ href, ...props }) => {
          if (href.indexOf(`www.toptekkers.com`) >= 0 || href.indexOf(`www.toptekkers.club`) >= 0) {
            return <Link to={href} fontFamily={theme.headingFont} color={theme.green} hoverColor={theme.greenDark} {...props} />;
          }
          return <Link as="a" href={href} target="_blank" rel="noopener noreferrer" color={theme.green} hoverColor={theme.greenDark} fontFamily={theme.headingFont} {...props} />;
        },
        ul: props => <UnorderedList ml={[`6px`, null, null, `20px`]} pb={[`20px`, null, null, null, `40px`]} {...props} />,
        li: props => <ListItem listStyle="disc" {...props} />,
        img: ({ src, alt }) => <Image as="img" src={src} alt={alt} width="100%" pb={[`20px`, null, null, null, `40px`]} />,
        iframe: ({ embed_url: embedURL, type, provider_name: name, html: embedHTML, ...props }) => (
          <Box pb={[`20px`, null, `30px`]}>
            <Video>
              <iframe
                title={name}
                data-oembed={embedURL}
                data-oembed-type={type}
                data-oembed-provider={name}
                frameBorder="0"
                allowFullScreen
                {...props}
              >
                {embedHTML}
              </iframe>

            </Video>
          </Box>
        ),
      }}
      {...rest} // componentOverrides={componentOverrides}
    />
  );
};

HTMLRenderer.propTypes = {
  html: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
  ]).isRequired,
  theme: PropTypes.object.isRequired,
};

export default withTheme(HTMLRenderer);