import styled from "styled-components";
import Box from "./box";

const Container = styled(Box)`
  box-sizing: ${p => p.boxSizing};
  z-index: ${p => p.zIndex};
  position: relative;
`;

Container.defaultProps = {
  textAlign: `left`,
  maxWidth: `1200px`,
  mx: `auto`,
  px: [`20px`, `30px`, `60px`, null, `100px`],
  boxSizing: `content-box`,
};

export default Container;
