import styled from "styled-components";
import PropTypes from "prop-types";
import { variant } from "styled-system";

import Text from "./text";

const Heading = styled(Text)`
  width: 100%;

  ${p => p.capitalize
    && `
    text-transform: capitalize;
  `}

  ${variant({
    variants: {
      h2: {
        fontSize: [`28px`, null, `38px`],
        lineHeight: [`38px`, null, `52px`],
        mb: [`20px`, null, `30px`],
      },
      h3: {
        fontSize: [`22px`, null, `32px`],
        lineHeight: [`32px`, null, `46px`],
        mb: [`20px`, null, `24px`],
      },
      h4: {
        fontSize: [`18px`, null, `22px`],
        lineHeight: [`28px`, null, `36px`],
        mb: [`20px`, null, `24px`],
      },
      h5: {
        fontSize: [`16px`, null, `20px`],
        lineHeight: [`26px`, null, `34px`],
        mb: [`20px`, null, `24px`],
      },
      h6: {
        fontSize: [`16px`, null, `20px`],
        lineHeight: [`26px`, null, `34px`],
        mb: [`20px`, null, `24px`],
      },
    },
  })};
`;

Heading.defaultProps = {
  as: `h1`,
  color: `#12253C`,
  mb: [20, null, null, 30, 40],
  fontSize: [28, null, 38, null, 48],
  // lineHeight not converting to px (without quotes)
  lineHeight: [`38px`, null, `52px`, null, `62px`],
  maxWidth: `681px`,
};

Heading.propTypes = {
  as: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
};

export default Heading;
