import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

const SEO = ({ description, lang, title, ogUrl, ogImage, path, noIndex, children }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            author
            title
            description
            siteUrl
          }
        }
      }
    `,
  );

  const metaTitle = title ? `${title} / TopTekkers` : site.siteMetadata.title;
  const metaDescription = description || site.siteMetadata.description;

  return (
    <Helmet htmlAttributes={{ lang }} title={metaTitle}>
      {noIndex && <meta name="robots" content="noindex" />}
      <meta name="author" content={site.siteMetadata.author} />

      <meta property="og:site_name" content="TopTekkers" />

      <meta property="og:image" content={ogImage} />
      <meta
        property="og:image:alt"
        content="TopTekkers logo surrounded by football character illustrations."
      />
      <meta property="og:image:width" content="1200" key="og:image:width" />
      <meta property="og:image:height" content="630" key="og:image:height" />

      <meta property="og:type" content="website" />
      <meta property="og:url" content={ogUrl} />
      <meta property="og:title" content={metaTitle} />
      <meta property="og:description" content={metaDescription} />

      <meta property="description" content={metaDescription} />

      {/* Canonical */}
      {typeof path === `string`
        && <link rel="canonical" href={`https://www.toptekkers.com${path}`} />
      }
      {children}

      {/* Facebook */}
      <meta property="fb:app_id" content="740849599667822" />

      {/* Twitter */}
      <meta property="twitter:card" content="summary" />
      <meta property="twitter:site" content="@TopTekkersApp" />
      <meta property="twitter:title" content={metaTitle} />
      <meta property="twitter:description" content={metaDescription} />

      {/* Favicons */}
      <link
        rel="apple-touch-icon"
        sizes="57x57"
        href="favicons/apple-icon-57x57.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="60x60"
        href="favicons/apple-icon-60x60.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="72x72"
        href="favicons/apple-icon-72x72.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="76x76"
        href="favicons/apple-icon-76x76.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="114x114"
        href="favicons/apple-icon-114x114.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="120x120"
        href="favicons/apple-icon-120x120.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="144x144"
        href="favicons/apple-icon-144x144.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="152x152"
        href="favicons/apple-icon-152x152.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="favicons/apple-icon-180x180.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="192x192"
        href="favicons/android-icon-192x192.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="favicons/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="96x96"
        href="favicons/favicon-96x96.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="favicons/favicon-16x16.png"
      />
      <link rel="manifest" href="favicons/manifest.json" />
      <meta name="msapplication-TileColor" content="#30B842" />
      <meta
        name="msapplication-TileImage"
        content="favicons/ms-icon-144x144.png"
      />
      <meta name="theme-color" content="#30B842" />

      <link href="/css/fonts.css" rel="stylesheet" />
    </Helmet>

  // Need Schema here
  );
};

SEO.defaultProps = {
  lang: `en`,
  ogUrl: `https://toptekkers.com`,
  ogImage: `https://toptekkers.com/ogImage.jpg`,
};

SEO.propTypes = {
  ogUrl: PropTypes.string,
  ogImage: PropTypes.string,
  description: PropTypes.string,
  lang: PropTypes.string,
  title: PropTypes.string,
  path: PropTypes.string.isRequired,
  noIndex: PropTypes.bool,
  children: PropTypes.object,
};

export default SEO;
