import { space, zIndex, maxWidth, border } from "styled-system";
import styled, { withTheme } from "styled-components";

const Root = styled.section(space, zIndex, maxWidth, border);

const Section = styled(Root)`
  position: relative;
  background-color: ${p => (p.bg ? p.bg : p.theme.white)};
`;

Section.defaultProps = {
  zIndex: 5,
  pb: [60, 80, 100, 140],
  mx: `auto`,
};

export default withTheme(Section);
