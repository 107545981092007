import styled from "styled-components";
import PropTypes from "prop-types";
import { compose, space, color, typography } from "styled-system";

const ListItem = styled.li`
${compose(
    space,
    color,
    typography,
  )}

  list-style: ${p => p.listStyle || `none`};
`;

ListItem.defaultProps = {
  color: `#666666`,
  lineHeight: `32px`,
  mb: `12px`,
};

ListItem.propTypes = {
  children: PropTypes.object.isRequired,
};

export default ListItem;